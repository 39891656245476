







































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AdminAction,
  AdminActionType,
  AdminState,
  CompetencesTableRow,
  UserGetter,
  UserGetterType
} from '@/store/modules/admin/types';
import { CompetenceType } from '@/constants/competencesFormObj';
import { Models } from '@mtap-smartcity/lib-api';
import ConfirmChangeModal from './ConfirmChangeModal/ConfirmChangeModal.vue';

type PermissionType = 'read' | 'write' | 'delete';

interface HTMLCheckboxInputElement extends HTMLInputElement {
  ariaChecked: string;
}

const admin = namespace('admin');

// this should be an array of all available competences; complete it and move to separate file in constants folder

@Component({
  components: {
    ConfirmChangeModal
  }
})
/**
 * @group Users Card
 * Users tab right panel - Group competences list
 */
export default class UsersCardRightPanelCompetences extends Vue {
  keycloakRoles: Models.Auth.Groups.GroupsModel[] = [];

  groupCompetencesArray: CompetencesTableRow[] = [];

  pending = true;

  key = true;

  roleArray: Models.Auth.Roles.RolesModel[] = [];

  toggleControl = true;

  dialog = false;

  removingUserCompetence = false;

  @admin.State
  user!: AdminState['user'];

  @admin.State
  selectedGroup!: AdminState['selectedGroup'];

  @admin.State
  availableRoles!: AdminState['availableRoles'];

  @admin.Action(AdminAction.SetUserPermissions)
  SetUserPermissions!: AdminActionType['SET_USER_PERMISSIONS'];

  @admin.Action(AdminAction.FetchGroupRoles)
  FetchGroupRoles!: AdminActionType['FETCH_GROUP_ROLES'];

  @admin.Action(AdminAction.ModifyGroupRoles)
  ModifyGroupRoles!: AdminActionType['MODIFY_GROUP_ROLES'];

  @admin.Action(AdminAction.GroupCompetences)
  GroupCompetences!: AdminActionType['GROUP_COMPETENCES'];

  @admin.Getter(UserGetter.GetFilteredGroupRoles)
  GetFilteredGroupRoles!: UserGetterType['GET_FILTERED_GROUP_ROLES'];

  @admin.Getter(UserGetter.GetGroupRoles)
  GetGroupRoles!: UserGetterType['GET_GROUP_ROLES'];

  get groupName(): string {
    return this.selectedGroup ? this.selectedGroup.name : '';
  }

  openModal() {
    this.dialog = true;
  }

  discardSubmit() {
    this.removingUserCompetence = false;
    this.dialog = false;
  }

  confirmSubmit() {
    this.removingUserCompetence = false;
    this.dialog = false;
    this.submitCompetences();
  }

  @Watch('keycloakRoles', { deep: true, immediate: true })
  async onKeycloakRolesChange(roles: Models.Auth.Roles.RolesModel[]) {
    this.groupCompetencesArray = await this.GroupCompetences(roles);
  }

  @Watch('selectedGroup', { immediate: true })
  async onSelectedGroupChange(group: Models.Auth.Groups.GroupsModel) {
    this.pending = true;
    this.roleArray = [];
    if (!group) return;
    if (!group?.id) return;
    await this.fetchGroupRoles(group.id);
    this.pending = true;
    this.roleArray = [];
  }

  async fetchGroupRoles(groupId: string) {
    this.pending = true;
    try {
      await this.FetchGroupRoles(groupId);
      this.keycloakRoles = this.GetFilteredGroupRoles(groupId);
      const user = this.user ?? null;
      if (!user.groupId) return;
      if (user.groupId.some((e) => e.id === groupId)) {
        this.SetUserPermissions(this.keycloakRoles);
      }
    } catch (error) {
      this.keycloakRoles = [];
      console.error(error);
    }
    this.pending = false;
  }

  preSubmit(): void {
    const userBelongsToEditedGroup = this.user.groupId && this.user.groupId.some((e) => e.id === this.selectedGroup?.id);
    const isRemovingCompetences = this.roleArray.some(
      (role: Models.Auth.Roles.RolesModel) => !role.roleEnabled
    );
    if (userBelongsToEditedGroup && isRemovingCompetences) {
      const isRemovingUsersWrite = this.roleArray.some(
        (role: Models.Auth.Roles.RolesModel) => /users_write/.test(role.name)
      );
      this.removingUserCompetence = isRemovingUsersWrite;
      this.openModal();
    } else this.submitCompetences();
  }

  async submitCompetences(): Promise<void> {
    try {
      await this.ModifyGroupRoles({ groupId: this.selectedGroup!.id!, roles: this.roleArray });
      this.roleArray = [];
      this.pending = true;
      this.toggleControl = true;
      if (this.user.groupId && this.user.groupId.some((e) => e.id === this.selectedGroup?.id)) {
        this.fetchGroupRoles(this.selectedGroup?.id!);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async cancelCompetences() {
    this.$emit('clicked');
    this.roleArray = [];
    this.pending = true;
    this.toggleControl = true;
  }

  editCompetences() {
    this.pending = false;
    this.toggleControl = !this.toggleControl;
  }

  async toggleCompetence({ permission, type, value }: { permission: PermissionType, type: CompetenceType, value: boolean }) {
    const role: Models.Auth.Roles.RolesModel | undefined = this.availableRoles.find(
      (r) => r.name === `${type}_${permission}`
    );
    if (!role) return;

    const competenceToBeModified: CompetencesTableRow | undefined = this.groupCompetencesArray.find((c) => c.type === type);
    if (!competenceToBeModified) return;

    if (permission !== 'read' && value) {
      this.setRoleReadTrue(type);
      if (type === 'circuits' || type === 'groups') this.setRoleReadTrue('devices');
    }

    if (permission === 'read' && !value) {
      this.unsetOtherRoles(type);
      if (type === 'devices') {
        this.unsetOtherRoles('circuits');
        this.unsetOtherRoles('groups');
      }
    }

    const duplicateRoleCheck = competenceToBeModified[permission] === value;
    if (duplicateRoleCheck) {
      this.roleArray = this.roleArray.filter(
        (obj: Models.Auth.Roles.RolesModel) => obj.name !== `${type}_${permission}`
      );
      return;
    }

    this.roleArray.push({
      userId: this.selectedGroup!.id!,
      id: role.id,
      name: role.name,
      roleEnabled: !!value
    });
  }

  setRoleReadTrue(type: CompetenceType): void {
    const readRoleCheckbox: HTMLCheckboxInputElement | null = document.querySelector(`input#role-checkbox-${this.groupName}-${type}--read`);
    if (!readRoleCheckbox) return;
    if (readRoleCheckbox.ariaChecked === 'true') return;
    readRoleCheckbox.click();
  }

  unsetOtherRoles(type: CompetenceType): void {
    const writeRoleCheckbox: HTMLCheckboxInputElement | null = document.querySelector(`input#role-checkbox-${this.groupName}-${type}--write`);
    const deleteRoleCheckbox: HTMLCheckboxInputElement | null = document.querySelector(`input#role-checkbox-${this.groupName}-${type}--delete`);
    if (writeRoleCheckbox && writeRoleCheckbox.ariaChecked === 'true') {
      writeRoleCheckbox.click();
    }
    if (deleteRoleCheckbox && deleteRoleCheckbox.ariaChecked === 'true') {
      deleteRoleCheckbox.click();
    }
  }
}

